import type { TechnologyCardProps } from "@components/TechnologyCardProps.js";
import brackets from "@core/assets/icons/brackets.svg?raw";
import cloud from "@core/assets/icons/cloud.svg?raw";
import code from "@core/assets/icons/code.svg?raw";
import db from "@core/assets/icons/db.svg?raw";
import git from "@core/assets/icons/git.svg?raw";
import phone from "@core/assets/icons/smartphone.svg?raw";
import type { Tenant } from "@core/schema/Tenant.js";

export function getTechnologies(tenant: Tenant): TechnologyCardProps[] {
	return [
		{
			title: "Jazyky",
			iconName: brackets,
			items: ["Svelte", "Angular", "React", "Vue", "Node.js", "TypeScript"],
		},
		{
			title: "Kódování",
			iconName: code,
			items: ["Tailwind", "SCSS/SASS"],
		},
		{
			title: "Mobilní aplikace",
			iconName: phone,
			items: ["React Native", "Ionic"],
		},
		{
			title: "Repozitář",
			iconName: git,
			items: ["GitHub", "GitLab", "Bitbucket"],
		},
		{
			title: "Databáze",
			iconName: db,
			items: ["DynamoDB", "PostgreSQL", "MongoDB", "MySQL"],
		},
		{
			title: "Cloud",
			iconName: cloud,
			items: ["AWS", "Azure", "Google Cloud", "Linux"],
		},
	];
}
